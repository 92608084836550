exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-shared-video-js": () => import("./../../../src/pages/shared-video.js" /* webpackChunkName: "component---src-pages-shared-video-js" */),
  "component---src-templates-author-js": () => import("./../../../src/templates/author.js" /* webpackChunkName: "component---src-templates-author-js" */),
  "component---src-templates-blog-list-js": () => import("./../../../src/templates/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-blog-video-js": () => import("./../../../src/templates/blog-video.js" /* webpackChunkName: "component---src-templates-blog-video-js" */),
  "component---src-templates-empty-js": () => import("./../../../src/templates/empty.js" /* webpackChunkName: "component---src-templates-empty-js" */),
  "component---src-templates-game-js": () => import("./../../../src/templates/game.js" /* webpackChunkName: "component---src-templates-game-js" */),
  "component---src-templates-games-list-js": () => import("./../../../src/templates/games-list.js" /* webpackChunkName: "component---src-templates-games-list-js" */),
  "component---src-templates-highlights-game-list-js": () => import("./../../../src/templates/highlights-game-list.js" /* webpackChunkName: "component---src-templates-highlights-game-list-js" */),
  "component---src-templates-highlights-list-js": () => import("./../../../src/templates/highlights-list.js" /* webpackChunkName: "component---src-templates-highlights-list-js" */),
  "component---src-templates-homepage-js": () => import("./../../../src/templates/homepage.js" /* webpackChunkName: "component---src-templates-homepage-js" */),
  "component---src-templates-leaderboard-template-js": () => import("./../../../src/templates/leaderboardTemplate.js" /* webpackChunkName: "component---src-templates-leaderboard-template-js" */),
  "component---src-templates-thank-you-js": () => import("./../../../src/templates/thank-you.js" /* webpackChunkName: "component---src-templates-thank-you-js" */),
  "component---src-templates-video-detail-js": () => import("./../../../src/templates/video-detail.js" /* webpackChunkName: "component---src-templates-video-detail-js" */),
  "component---src-templates-video-list-js": () => import("./../../../src/templates/video-list.js" /* webpackChunkName: "component---src-templates-video-list-js" */)
}

